import React from 'react';

import { MainPage } from "pages/MainPage";

import 'styles/reset.css';
import 'styles/normalize.css';
import './App.css';

function App() {

    return (
        <MainPage />
    );
}

export default App;
