// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-MainPage-__main--o358G{width:100vw;height:100vh;display:flex;flex-direction:column;justify-content:center;align-items:center;background-color:#0e0e0e;color:#f5f5f5;padding:30vh 0 40vh 0;user-select:none}.src-pages-MainPage-__mainCaption--N_dBb{font-size:72pt;margin-bottom:2vh;z-index:2}.src-pages-MainPage-__mainDescription--bS4oy{font-size:24pt;text-transform:uppercase;z-index:2}`, "",{"version":3,"sources":["webpack://./src/pages/MainPage/styles.module.scss"],"names":[],"mappings":"AACA,kCACE,WAAA,CACA,YAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,wBAAA,CACA,aAAA,CACA,qBAAA,CACA,gBAAA,CAEA,yCACE,cAAA,CACA,iBAAA,CACA,SAAA,CAGF,6CACE,cAAA,CACA,wBAAA,CACA,SAAA","sourcesContent":["\r\n.main {\r\n  width: 100vw;\r\n  height: 100vh;\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  align-items: center;\r\n  background-color: #0e0e0e;\r\n  color: #f5f5f5;\r\n  padding: 30vh 0 40vh 0;\r\n  user-select: none;\r\n\r\n  &Caption {\r\n    font-size: 72pt;\r\n    margin-bottom: 2vh;\r\n    z-index: 2;\r\n  }\r\n\r\n  &Description {\r\n    font-size: 24pt;\r\n    text-transform: uppercase;\r\n    z-index: 2;\r\n  }\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `src-pages-MainPage-__main--o358G`,
	"mainCaption": `src-pages-MainPage-__mainCaption--N_dBb`,
	"mainDescription": `src-pages-MainPage-__mainDescription--bS4oy`
};
export default ___CSS_LOADER_EXPORT___;
