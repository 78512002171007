// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("fonts/PT_Sans/PTSans-Italic.ttf?", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("fonts/PT_Sans/PTSans-Bold.ttf?", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("fonts/PT_Sans/PTSans-BoldItalic.ttf?", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("fonts/PT_Sans/PTSans-Regular.ttf?", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("fonts/PT_Sans_Caption/PTSansCaption-Regular.ttf?", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("fonts/PT_Sans_Caption/PTSansCaption-Bold.ttf?", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: "PTSans";
    font-style: italic;
    font-weight: normal;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}

@font-face {
    font-family: "PTSans";
    font-style: normal;
    font-weight: bold;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}

@font-face {
    font-family: "PTSans";
    font-style: italic;
    font-weight: bold;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
}

@font-face {
    font-family: "PTSans";
    font-style: normal;
    font-weight: normal;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
}



@font-face {
    font-family: "PTSansCaption";
    font-style: normal;
    font-weight: normal;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("truetype");
}

@font-face {
    font-family: "PTSansCaption";
    font-style: normal;
    font-weight: bold;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("truetype");
}`, "",{"version":3,"sources":["webpack://./src/static/fonts.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,mBAAmB;IACnB,+DAA+D;AACnE;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,iBAAiB;IACjB,+DAA6D;AACjE;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,iBAAiB;IACjB,+DAAmE;AACvE;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,mBAAmB;IACnB,+DAAgE;AACpE;;;;AAIA;IACI,4BAA4B;IAC5B,kBAAkB;IAClB,mBAAmB;IACnB,+DAA+E;AACnF;;AAEA;IACI,4BAA4B;IAC5B,kBAAkB;IAClB,iBAAiB;IACjB,+DAA4E;AAChF","sourcesContent":["@font-face {\r\n    font-family: \"PTSans\";\r\n    font-style: italic;\r\n    font-weight: normal;\r\n    src: url(\"fonts/PT_Sans/PTSans-Italic.ttf?\") format(\"truetype\");\r\n}\r\n\r\n@font-face {\r\n    font-family: \"PTSans\";\r\n    font-style: normal;\r\n    font-weight: bold;\r\n    src: url(\"fonts/PT_Sans/PTSans-Bold.ttf?\") format(\"truetype\");\r\n}\r\n\r\n@font-face {\r\n    font-family: \"PTSans\";\r\n    font-style: italic;\r\n    font-weight: bold;\r\n    src: url(\"fonts/PT_Sans/PTSans-BoldItalic.ttf?\") format(\"truetype\");\r\n}\r\n\r\n@font-face {\r\n    font-family: \"PTSans\";\r\n    font-style: normal;\r\n    font-weight: normal;\r\n    src: url(\"fonts/PT_Sans/PTSans-Regular.ttf?\") format(\"truetype\");\r\n}\r\n\r\n\r\n\r\n@font-face {\r\n    font-family: \"PTSansCaption\";\r\n    font-style: normal;\r\n    font-weight: normal;\r\n    src: url(\"fonts/PT_Sans_Caption/PTSansCaption-Regular.ttf?\") format(\"truetype\");\r\n}\r\n\r\n@font-face {\r\n    font-family: \"PTSansCaption\";\r\n    font-style: normal;\r\n    font-weight: bold;\r\n    src: url(\"fonts/PT_Sans_Caption/PTSansCaption-Bold.ttf?\") format(\"truetype\");\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
