import React, { useCallback } from "react";
import { loadFull } from "tsparticles";
import Particles from "react-tsparticles";

import styles from './styles.module.scss';

export const MainPage: React.FC = () => {

    const particlesInit = useCallback(async engine => {
        await loadFull(engine);
    }, []);

    // @ts-ignore
    ym(95081026, 'hit', '/');

    return (
        <main className={styles.main}>
            <Particles
                id="tsparticles"
                init={particlesInit}
                options={{
                    background: {
                        color: {
                            value: "#0e0e0e",
                        },
                    },
                    fpsLimit: 120,
                    interactivity: {
                        events: {
                            onHover: {
                                enable: true,
                                mode: "repulse",
                            },
                            resize: true,
                        },
                        modes: {
                            repulse: {
                                distance: 100,
                                duration: 0.4,
                            },
                        },
                    },
                    particles: {
                        color: {
                            value: "#f5f5f5",
                        },
                        links: {
                            color: "#f5f5f5",
                            distance: 150,
                            enable: true,
                            opacity: 0.3,
                            width: 1,
                        },
                        collisions: {
                            enable: true,
                            mode: "bounce",
                        },
                        move: {
                            direction: "none",
                            enable: true,
                            outModes: {
                                default: "bounce",
                            },
                            random: false,
                            speed: 3,
                            straight: false,
                        },
                        number: {
                            density: {
                                enable: true,
                                area: window.innerWidth,
                            },
                            max: 100,
                            value: 80,
                        },
                        opacity: {
                            value: 0.5,
                        },
                        shape: {
                            type: "polygon",
                        },
                        size: {
                            value: { min: 1, max: 5 },
                        },
                    },
                    detectRetina: true,
                }}
            />
            <p className={styles.mainCaption}>MLedIum</p>
            <p className={styles.mainDescription}>Разработка и управление</p>
        </main>
    )
}